// src/Footer.js
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-line top"></div> {/* 최상단 선 */}
        <div className="footer-content">
          저에게 의뢰를<br></br>
          하고 싶으신가요?
        </div>
        <div className="footer-container">
          <div className="footer-service">
            <span>lhw706@naver.com</span>
            <Link to="/contact">
              <button className="plus-button">+</button>
            </Link>
          </div>
          <ul className="footer-items">
            <li>
              Email<br></br>
              <br></br>lhw706@naver.com
            </li>
            <li>
              Contact us<br></br>
              <br></br>010-7928-1887
            </li>
            <li>
              <br></br>SNS<br></br>
              <br></br>instagram<br></br>blog
            </li>
          </ul>
        </div>
        <div className="footer-line bottom"></div> {/* 최하단 선 */}
      </footer>
      <div className="footer-bottom">
        Copyright 2024 21ul. All rights reserved
      </div>{" "}
      {/* footer 바깥으로 이동 */}
    </>
  );
}

export default Footer;
