import React, { useEffect } from "react";
import "./Menu.css";
import { Link, useLocation } from "react-router-dom";

const Menu = ({ isOpen, setIsMenuOpen }) => {
  const location = useLocation();

  useEffect(() => {
    // 경로가 변경될 때 메뉴 상태를 false로 설정하여 메뉴를 닫습니다.
    setIsMenuOpen(false);
  }, [location, setIsMenuOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`menu ${isOpen ? "open" : "closed"}`}>
      <ul>
        <li>
          <Link to="/about" className="menu-link">
            ABOUT
          </Link>
        </li>
        <li className="menu-item">
          <Link to="/project" className="menu-link">
            PROJECT
          </Link>
          <div className="project-count">
            <span>9</span>
          </div>
        </li>
        <li>
          <Link to="/contact" className="menu-link">
            CONTACT
          </Link>
        </li>
      </ul>
      <div className="side-panel">
        <div className="bottom-area">
          <div className="left-half">Instagram</div>
          <div className="middle-half">Blog</div>
          <div className="right-half">21ul</div>
        </div>
        <div className="contact-us">
          <p className="info">Contact us</p>
          <p>010-7928-1887</p>
          <br></br>
          <p className="info">Email</p>
          <p>lhw706@naver.com</p>
          <br></br>
          <p className="info">Address</p>
          <p>인천광역시 미추홀구 문화로23</p>
          <p>(관교동)</p>
        </div>
      </div>
    </div>
  );
};

export default Menu;
