import React, { useEffect, useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [clickedSquares, setClickedSquares] = useState(Array(4).fill(false));
  const [isFixed, setIsFixed] = useState(false);
  const [bottomReached, setBottomReached] = useState(false);

  const handleSquareClick = (index) => {
    const updatedClickedSquares = [...clickedSquares];
    updatedClickedSquares[index] = !updatedClickedSquares[index];
    setClickedSquares(updatedClickedSquares);
  };

  useEffect(() => {
    const elementsToHide = document.querySelectorAll(
      ".main-visual, .portfolio-container, .vertical-line, .App::before, .App::after"
    );
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    return () => {
      elementsToHide.forEach((element) => {
        element.style.display = "";
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const contactText = document.querySelector(".contact-text");
      const designOption = document.querySelector(".design-option");

      if (!contactText || !designOption) return;

      const contactRect = contactText.getBoundingClientRect();
      const designRect = designOption.getBoundingClientRect();

      const isFixedPosition =
        designRect.top <= 20 && designRect.bottom > contactRect.height + 20;
      setIsFixed(isFixedPosition);
      setBottomReached(designRect.bottom <= contactRect.height + 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="contact-container">
        <div className="svg-container">
          <svg
            viewBox="0 0 100 100"
            width="400"
            height="400"
            className="rotate-circle"
          >
            <path
              id="circlePath"
              fill="none"
              d="M50,50 m-35,0 a35,35 0 1,0 70,0 a35,35 0 1,0 -70,0"
            ></path>
            <text fill="#727272">
              <textPath
                href="#circlePath"
                startOffset="10%"
                method="stretch"
                spacing="auto"
                style={{ fontSize: "6px", letterSpacing: "1.5px" }}
              >
                끊임없이 생각하고 발전하는 디자이너 이한울입니다.
              </textPath>
            </text>
          </svg>
        </div>
        <div className="project">
          <h1>
            저에게 <span className="highlight">맡기실 의뢰가</span>
            <br />
            있으신가요?
          </h1>
        </div>
        <div className="project-line"></div>
      </div>
      <div className="contact-text-container">
        <div
          className={`contact-text ${isFixed ? "contact-text-fixed" : ""} ${
            bottomReached ? "contact-text-bottom" : ""
          }`}
        >
          <p
            style={{
              color: "#fff",
              fontSize: "48px",
              marginBottom: "40px",
            }}
          >
            안녕하세요!
          </p>
          <p style={{ color: "#999", fontSize: "20px", marginBottom: "15px" }}>
            빠른 답변을 위해
          </p>
          <p style={{ color: "#999", fontSize: "20px", marginBottom: "15px" }}>
            저에게 의뢰하실 디자인에 대한
          </p>
          <p style={{ color: "#999", fontSize: "20px", marginBottom: "15px" }}>
            내용을 선택해 주세요.
          </p>
          <p style={{ color: "#fff", fontSize: "20px", marginTop: "70px" }}>
            밑으로 스크롤하며 선택해 주세요.
          </p>
          <p
            className="triangle1"
            style={{ color: "#fff", fontSize: "20px", marginTop: "70px" }}
          ></p>
          <p
            className="triangle2"
            style={{ color: "#fff", fontSize: "20px", marginTop: "10px" }}
          ></p>
        </div>
        <div className="design-option">
          <div className="additional-box">
            <p style={{ color: "#fff", fontSize: "26px", fontWeight: "bold" }}>
              어떤 디자인을 문의하고 싶으신가요?
            </p>
            <p
              style={{
                color: "#727272",
                fontSize: "16px",
                marginBottom: "40px",
                fontWeight: "regular",
              }}
            >
              (중복 선택이 가능합니다.)
            </p>
            <div className="square-container">
              <span
                className={`square ${clickedSquares[0] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(0)}
              >
                기업 사이트
              </span>
              <span
                className={`square ${clickedSquares[1] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(1)}
              >
                이커머스
              </span>
              <span
                className={`square ${clickedSquares[2] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(2)}
              >
                상세페이지
              </span>
              <span
                className={`square ${clickedSquares[3] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(3)}
              >
                인쇄물
              </span>
            </div>
            <div className="square-container">
              <span
                className={`square ${clickedSquares[4] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(4)}
              >
                랜딩페이지
              </span>
              <span
                className={`square ${clickedSquares[5] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(5)}
              >
                APP
              </span>
              <span
                className={`square ${clickedSquares[6] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(6)}
              >
                유지/보수
              </span>
              <span
                className={`square ${clickedSquares[7] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(7)}
              >
                기타
              </span>
            </div>
          </div>
          <div className="additional-box">
            <p
              style={{
                color: "#999",
                fontSize: "26px",
                fontWeight: "400",
                marginTop: "165px",
              }}
            >
              프로젝트명을 알려주세요.
            </p>
            <p
              style={{
                color: "#727272",
                fontSize: "16px",
                marginBottom: "40px",
                fontWeight: "regular",
              }}
            ></p>
            <div className="project-title">
              <textarea
                placeholder="프로젝트명"
                style={{
                  fontFamily: "'Pretendard', sans-serif",
                  background: "#111",
                  color: "#fff",
                  width: "calc(100% - 20px)",
                  height: "80px",
                  border: "1px solid #fff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  maxWidth: "100%",
                  padding: "20px",
                  resize: "none",
                }}
              />
            </div>
          </div>
          <div className="additional-box">
            <p
              style={{
                color: "#999",
                fontSize: "26px",
                fontWeight: "400",
                marginTop: "165px",
              }}
            >
              프로젝트 기간을 알려주세요.
            </p>
            <p
              style={{
                color: "#727272",
                fontSize: "16px",
                marginBottom: "40px",
                fontWeight: "regular",
              }}
            ></p>
            <div className="square-container">
              <span
                className={`square ${clickedSquares[8] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(8)}
              >
                2주 이내
              </span>
              <span
                className={`square ${clickedSquares[9] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(9)}
              >
                1개월
              </span>
              <span
                className={`square ${clickedSquares[10] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(10)}
              >
                3개월
              </span>
              <span
                className={`square ${clickedSquares[11] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(11)}
              >
                6개월 이상
              </span>
            </div>
          </div>
          <div className="additional-box">
            <p
              style={{
                color: "#999",
                fontSize: "26px",
                fontWeight: "400",
                marginTop: "165px",
              }}
            >
              프로젝트 예산을 알려주세요.
            </p>
            <p
              style={{
                color: "#727272",
                fontSize: "16px",
                marginBottom: "40px",
                fontWeight: "regular",
              }}
            ></p>
            <div className="square-container">
              <span
                className={`square ${clickedSquares[12] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(12)}
              >
                10만원 이내
              </span>
              <span
                className={`square ${clickedSquares[13] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(13)}
              >
                100만원 이내
              </span>
              <span
                className={`square ${clickedSquares[14] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(14)}
              >
                100만원 초과
              </span>
              <span
                className={`square ${clickedSquares[15] ? "clicked" : ""}`}
                onClick={() => handleSquareClick(15)}
              >
                미정
              </span>
            </div>
          </div>
          <div className="additional-box">
            <p
              style={{
                color: "#999",
                fontSize: "26px",
                fontWeight: "400",
                marginTop: "165px",
              }}
            >
              프로젝트에 대해 알려주세요.
            </p>
            <p
              style={{
                color: "#727272",
                fontSize: "16px",
                marginBottom: "40px",
                fontWeight: "regular",
              }}
            ></p>
            <div className="project-title">
              <textarea
                placeholder="의뢰할 내용을 기재해 주세요."
                style={{
                  fontFamily: "'Pretendard', sans-serif",
                  background: "#111",
                  color: "#fff",
                  width: "calc(100% - 20px)",
                  height: "190px",
                  border: "1px solid #fff",
                  padding: "20px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  resize: "none",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
          <div className="additional-box">
            <p
              style={{
                color: "#999",
                fontSize: "26px",
                fontWeight: "400",
                marginTop: "165px",
              }}
            >
              담당자를 소개해 주세요.
            </p>
            <p
              style={{
                color: "#727272",
                fontSize: "16px",
                marginBottom: "40px",
                fontWeight: "regular",
              }}
            ></p>
            <div className="project-title">
              <textarea
                placeholder="회사명"
                style={{
                  fontFamily: "'Pretendard', sans-serif",
                  background: "#111",
                  color: "#fff",
                  width: "calc(100% - 20px)",
                  height: "65px",
                  border: "1px solid #fff",
                  padding: "20px",
                  fontSize: "16px",
                  resize: "none",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              />
              <textarea
                placeholder="담당자 성함"
                style={{
                  fontFamily: "'Pretendard', sans-serif",
                  background: "#111",
                  color: "#fff",
                  width: "calc(100% - 20px)",
                  height: "65px",
                  border: "1px solid #fff",
                  padding: "20px",
                  fontSize: "16px",
                  resize: "none",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              />
              <textarea
                placeholder="연락처 또는 메일주소"
                style={{
                  fontFamily: "'Pretendard', sans-serif",
                  background: "#111",
                  color: "#fff",
                  width: "calc(100% - 20px)",
                  height: "65px",
                  border: "1px solid #fff",
                  padding: "20px",
                  fontSize: "16px",
                  resize: "none",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  boxSizing: "border-box",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>
          <div
            className="additional-box"
            style={{
              marginTop: "80px",
              textAlign: "right",
            }}
          >
            <button
              style={{
                width: "112px",
                height: "54px",
                backgroundColor: "#222",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "bold",
                border: "none",
                cursor: "pointer",
                marginRight: "20px",
              }}
            >
              <span
                style={{
                  position: "relative",
                  top: "-1px",
                  right: "-1px",
                }}
              >
                제출하기
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
