import React, { useEffect, useState } from "react";
import "./Project.css";

const Project = () => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    const elementsToHide = document.querySelectorAll(
      ".main-visual, .portfolio-container, .vertical-line, .App::before, .App::after"
    );
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    return () => {
      elementsToHide.forEach((element) => {
        element.style.display = "";
      });
    };
  }, []);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  useEffect(() => {
    const rectangleContainer2 = document.querySelector(".rectangle-container2");
    const moreButton = document.querySelector(".more-button");

    if (rectangleContainer2 && moreButton) {
      rectangleContainer2.style.display = isHidden ? "none" : "";
      moreButton.style.display = isHidden ? "" : "none";
    }
  }, [isHidden]);

  return (
    <div className="wrapper" style={{ marginBottom: "200px" }}>
      <div className="project-container">
        <div class="svg-container">
          <svg
            viewBox="0 0 100 100"
            width="400"
            height="400"
            class="rotate-circle"
          >
            <path
              id="circlePath"
              fill="none"
              d="M50,50 m-35,0 a35,35 0 1,0 70,0 a35,35 0 1,0 -70,0"
            ></path>
            <text fill="#727272">
              <textPath
                href="#circlePath"
                startOffset="10%"
                method="stretch"
                spacing="auto"
                style={{ fontSize: "6px", letterSpacing: "1.5px" }}
              >
                끊임없이 생각하고 발전하는 디자이너 이한울입니다.
              </textPath>
            </text>
          </svg>
        </div>
        <div className="project">
          <h1>
            저의 <span className="highlight">포트폴리오</span>를 <br />
            소개합니다.
          </h1>
        </div>
        <div className="project-line"></div>
      </div>
      <div className="rectangle-container" style={{ display: "flex" }}>
        <div className="rectangle" style={{ marginRight: "30px" }}>
          <a
            href="https://www.naver.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="Project01.jpg" alt="Using01" />
          </a>
        </div>
        <div className="rectangle" style={{ marginRight: "30px" }}>
          <img src="Project02.jpg" alt="Using01" />
        </div>
        <div className="rectangle">
          <img src="Project03.jpg" alt="Using01" />
        </div>
      </div>
      <div
        className="rectangle-container"
        style={{ marginTop: "30px", display: "flex" }}
      >
        <div className="rectangle" style={{ marginRight: "30px" }}>
          <img src="Project04.jpg" alt="Using01" />
        </div>
        <div className="rectangle" style={{ marginRight: "30px" }}>
          <a
            href="http://implineic.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="Project05.jpg" alt="Using01" />
          </a>
        </div>
        <div className="rectangle">
          <img src="Project06.jpg" alt="Using01" />
        </div>
      </div>
      <div
        className="rectangle-container2"
        style={{ marginTop: "30px", display: "flex" }}
      >
        <div className="rectangle2" style={{ marginRight: "30px" }}>
          <a
            href="https://shoppinglive.naver.com/livebridge/1255403"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="Project07.jpg" alt="Using01" />
          </a>
        </div>
        <div className="rectangle2" style={{ marginRight: "30px" }}>
          <a
            href="https://www.mobpa.co.kr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="Project08.jpg" alt="Using01" />
          </a>
        </div>
        <div className="rectangle2">
          <img src="Project09.jpg" alt="Using01" />
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <button className="more-button" onClick={toggleVisibility}>
          +
        </button>
      </div>
    </div>
  );
};

export default Project;
