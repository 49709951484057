import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import Footer from "./Footer";
import Menu from "./Menu";
import "./Menu.css";
import About from "./About";
import Project from "./Project";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

function AppContent() {
  const [isMobile, setIsMobile] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const textBox = document.querySelector(".text-box");
      const portfolioContainer = document.querySelector(".portfolio-container");

      if (textBox && portfolioContainer) {
        const portfolioContainerTop =
          portfolioContainer.getBoundingClientRect().top;
        const portfolioContainerBottom =
          portfolioContainer.getBoundingClientRect().bottom;

        // textBox가 portfolioContainer의 상단에 도달할 때 고정
        if (
          portfolioContainerTop <= 0 &&
          portfolioContainerBottom > textBox.offsetHeight
        ) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const location = useLocation();
  const isAboutPage = location.pathname === "/about";

  return (
    <div className="App">
      <div
        className="mouse-circle"
        style={{
          left: `${mousePosition.x}px`,
          top: `${mousePosition.y}px`,
        }}
      ></div>

      <div className="vertical-line"></div>
      <header className="header" onClick={(e) => e.stopPropagation()}>
        <div className="header-logo">
          <Link to="/">
            {" "}
            <img src="logo.png" alt="로고" />
          </Link>
        </div>
        <div className="header-content">
          <img src="Kr_translation.png" alt="번역" />
          <img src="light_mode.png" alt="밝은모드" />
          <img
            src={isMenuOpen ? "Menu-close.png" : "Menu.png"}
            alt="메뉴"
            className="menu-icon"
            onClick={(e) => {
              e.stopPropagation();
              toggleMenu();
            }}
          />
        </div>
      </header>
      <Menu isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

      {!isAboutPage && (
        <>
          <div className="main-visual">
            <div className="svg-container">
              <svg
                viewBox="0 0 100 100"
                width="400"
                height="400"
                className="rotate-circle"
              >
                <path
                  id="circlePath"
                  fill="none"
                  d="M50,50 m-35,0 a35,35 0 1,0 70,0 a35,35 0 1,0 -70,0"
                />
                <text fill="#727272">
                  <textPath
                    href="#circlePath"
                    startOffset="10%"
                    method="stretch"
                    spacing="auto"
                    style={{ fontSize: "6px", letterSpacing: "1.5px" }}
                  >
                    끊임없이 생각하고 발전하는 디자이너 이한울입니다.
                  </textPath>
                </text>
              </svg>
            </div>

            <div className="main-visual-text">
              생각을<br></br>
              표현하는<br></br>
              <br></br>
              나만의 공간<span className="blinking-underscore">__</span>
            </div>
          </div>

          <div className="portfolio-container">
            <div className={`text-box ${isFixed ? "fixed" : ""}`}>
              <div className="project-text">
                저의<br></br>
                <strong>작업물</strong>을<br></br>
                <br></br>
                소개합니다.
              </div>

              <div className="view-all">
                <span>더보기</span>
                <Link to="/project">
                  <button className="plus-button">+</button>
                </Link>
              </div>
            </div>

            <ul className="portfolio-images">
              <li>
                <a
                  href="http://implineic.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="Main01.jpg" alt="Using01" />
                </a>
              </li>
              <li>
                <img src="Main02.jpg" alt="Using01" />
              </li>
              <li>
                {" "}
                <img src="Main03.jpg" alt="Using01" />
              </li>
              <li>
                {" "}
                <img src="Main04.jpg" alt="Using01" />
              </li>
              <li>
                <a
                  href="http://implineic.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="Main05.jpg" alt="Using01" />
                </a>
              </li>
              <li>
                <a
                  href="https://shoppinglive.naver.com/livebridge/1255403"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="Main06.jpg" alt="Using01" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.mobpa.co.kr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="Main07.jpg" alt="Using01" />
                </a>
              </li>
              <li>
                {" "}
                <img src="Main08.jpg" alt="Using01" />
              </li>
            </ul>
          </div>
        </>
      )}

      <Footer />
      {!isMobile && (
        <div className="pc-optimized-message">
          PC버전 해상도에 최적화 되어 있습니다.
        </div>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;
