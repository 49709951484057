import React, { useEffect } from "react";
import "./About.css";

const About = () => {
  useEffect(() => {
    const textContent = document.querySelector(".thoughts-text").textContent;
    const lastChar = textContent.charAt(textContent.length - 1);
    document.querySelector(".thoughts-text").innerHTML =
      textContent.slice(0, -1) +
      '<span class="special">' +
      lastChar +
      "</span>";

    const experienceTextContent =
      document.querySelector(".experience-text").textContent;
    const lastCharExp = experienceTextContent.charAt(
      experienceTextContent.length - 1
    );
    document.querySelector(".experience-text").innerHTML =
      experienceTextContent.slice(0, -1) +
      '<span class="special">' +
      lastCharExp +
      "</span>";
  }, []);

  return (
    <div className="about">
      <p className="about-title">ABOUT</p>
      <div className="about-rectangle">
        <img src="AboutUs.jpg" alt="소개" />
      </div>
      <div className="unique-text">
        혁신을
        <br />
        확신으로
      </div>
      <div className="small-rectangle"></div>
      <div className="additional-rectangle"></div>
      <div className="final-text">
        항상 새로운 시도와 실험을 통해 독창적인 결과물을 만들어내려 노력합니다.
        <br />
        <br />
        사용자의 경험을 향상시키고, 실용성을 겸비한 디자인을 추구하며 UI/UX
        디자인, 마케팅 디자인, 코딩 등 다양한 작업을 진행하며 <br /> 사용자
        친화적인 인터페이스와 직관적인 경험을 제공하려 노력합니다.
        <br />
        <br />
        앞으로도 트렌드를 반영하면 끊임없이 나아가는 디자이너가 되려고합니다.{" "}
        <br /> 모두가 이해할 수 있는 수위의 방법으로 혁신을 확신으로 우리의
        상상력을 표현합니다.
      </div>
      <div className="my-service">MY SERVICE</div>
      <div className="circle-container">
        <div className="circle">
          <div className="text-group">
            <p className="english">DESIGN</p>
            <p className="korean">디자인</p>
          </div>
        </div>
        <div className="circle">
          <div className="text-group">
            <p className="english">PUBLISHING</p>
            <p className="korean">퍼블리싱</p>
          </div>
        </div>
        <div className="circle">
          <div className="text-group">
            <p className="english">PLANNING</p>
            <p className="korean">기획</p>
          </div>
        </div>
      </div>
      <div className="thoughts-container"></div>
      <p className="thoughts-text">
        끊임없는 배움을 추구하는 <br /> 디자이너 이한울입니다.
        <br />
      </p>
      <p className="additional-thoughts-text">
        디자이너 외 단 한번의 직무변경 없이 꾸준히 나아가고 있습니다. <br />
        Java, JavaScript, HTML, CSS3, Oracle DB, Vs Code, Photoshop,
        Illustrator, Adobe XD, figma, Lightroom, Demension 등의 툴 사용능력을
        보유하고 있으며
        <br /> 재직했던 회사에서 UIUX Design, Content Design, Promotion,
        Website, 오프라인 시안, 로고 등의 다양한 업무를 진행한 경험이 있으며
        <br />
        역량을 넓히기 위해 퇴사 후 6개월간 UIUX Design, front-end 지식을
        습득하였습니다.
      </p>
      <div className="additional-thoughts-container">
        <div className="experience-text">WORK.</div>
      </div>
      <div className="experience-description">
        <p style={{ fontSize: "18px" }}>
          <strong style={{ fontSize: "24px" }}>UI/UX designer</strong>
          <br />
          2011. - 2014 도림고등학교 인문계 졸업
          <br />
          2014. - 2019 세한대학교 시각디자인학과 전공
          <br />
          2015. - 2016 육군 병장 만기전역
          <br />
          2019. GDJS 디자인 캘린더 부문 우수상
          <br />
          2021. 라우드소싱 상세페이지 부문 2회 우승
          <br />
          2021. - 2022 아이눈커뮤니케이션(디자인뱅크) 웹 에이전시 근무
          <br />
          2022. - 2023 그룹디 웹 디자이너 근무
          <br />
          2023. 인천일보아카데미 JAVA 백엔드 개발자 양성과정 6개월 수료
          <br />
          2024. 에몬스가구 온라인 사업 본부 근무
        </p>
      </div>
    </div>
  );
};

export default About;
